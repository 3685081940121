import ProAbout from "../../components/Abouts/ProAbout";
import Chatbot from "../../components/Chatbot";
import Competencias from "../../components/Competencias";
import ProContact from "../../components/Contacts/ProContact";
import ProCta from "../../components/Cta/ProCta";
import ProFeatures from "../../components/Features/ProFeatures";
import ProFooter from "../../components/Footer/ProFooter";
import ProHero from "../../components/Heros/ProHero";
import LoopGrowth from "../../components/LoopGrowth";
import Menu from "../../components/Menu/Menu";
import PageSites from "../../components/PageSites";
import ProPrincing from "../../components/Pricings/ProPricing";
import ResultadosFace from "../../components/ResultadosFace";
import './stylepromova.css'
export default function Promova() {
  return (
    <>
      <main id="main">
        <Menu />
        <ProHero />
        <ProAbout />
        <LoopGrowth/>
        <Competencias />
        <ProCta/>
        <ProFeatures />
        <PageSites />
        <Chatbot />
        <ResultadosFace/>
        <ProPrincing />
        <ProContact />
        <ProFooter />
      </main>

    </>
  )
}