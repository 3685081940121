export default function ProFooter() {
    return (
        <>
            <footer id="footer">
                <div class="container">
                    <h3><img src="https://dlzumwzvsvpdqblhfoof.supabase.co/storage/v1/object/public/PromovaOnline/Logos/logo_2.png?t=2024-07-11T15%3A36%3A21.647Z" width="150px"/></h3>
                    <p>Ampliando suas vendas impactando clientes digitalmente com Marketing Digital!</p>
                    <div class="social-links">
                        <a target="blank" href="#" class="facebook"><i class="bi bi-facebook"></i></a>
                        <a target="blank" href="https://www.instagram.com/promovaonline.com.br/" class="instagram"><i class="bi bi-instagram"></i></a>
                        <a target="blank" href="https://wa.me/5561990003695" class="linkedin"><i class="bi bi-whatsapp"></i></a>
                    </div>
                    <div class="copyright">
                        &copy; Copyright <strong><span>ProMovaOnline CNPJ: 53.785.932/0001-53</span></strong>. Todos os direitos reservados
                    </div>
                    <div class="credits">

                        Criador por <a href="https://promovaonline.com.br/">Promova Online</a>
                    </div>
                </div>
            </footer>
            {/* <div id="preloader"></div> */}

        </>
    )
}


