export default function ProCta() {
    return (
        <>
            <section id="cta" className="cta mt-5" data-aos="fade-up" data-aos-duration="1000">
                <div class="container">

                    <div class="row">
                        <div class="col-lg-9 text-center text-lg-start">
                            <h3 className="gradient-text">Descubra agora as estrategias de marketing digital para o seu negócio.</h3>
                            <p></p>
                        </div>
                        <div class="col-lg-3 cta-btn-container text-center">
                            <a class="cta-btn align-middle" href="https://api.whatsapp.com/send/?phone=5561992387326&text=Ol%C3%A1+%2APromova+Online%2A%21+Gostaria+de+mais+informações+sobre+o+serviço+de+vocês+%3F(site)&type=phone_number&app_absent=0">Falar com o Especialista</a>
                        </div>
                    </div>

                </div>
            </section>

        </>
    )
}