import CarouselFace from "../Carousel/CarouselFace";

export default function ResultadosFace() {
  return (
    <>

      <section id="features" className="features" data-aos="fade-up" data-aos-duration="1000">
        <div className="container">

          <div className="row">
            <div className="col-lg-6 order-1 order-lg-1" >
              <div className="icon-box mt-5 mt-lg-5 mt-5" data-aos="fade-right" data-aos-duration="300">
                <i ><img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/favicon-192x192.png?t=2024-10-08T22%3A54%3A28.179Z" height="50" styled={{ opacity: "0.85" }} /></i>
                <h4 className="text-warning">Resultados Comprovados</h4>
                <p>Resultados reais de clientes reais em facebook Ads e Google Ads, com foco em CPA e ROI. Analisamos métricas e otimizamos seus resultados, monitorando suas campanhas 7 dias por semana. </p>
              </div>
            </div>
            <div className="col-lg-6 order-2 order-lg-2 px-5">
              <CarouselFace />
            </div>
          </div>

        </div>
      </section>
    </>
  )
}