import CarouselSite from "../Carousel/CarouselSite";
import "./pagesites.css"

export default function PageSites() {
    return(
        <>
        
        <section id="features" class="features" data-aos="fade-up" data-aos-duration="1000">
      <div class="container">

        <div class="row">
          <div class="col-lg-6 order-1 order-lg-2" >
            <div class="icon-box mt-5 mt-lg-5 mt-5" data-aos="fade-right" data-aos-duration="300"> 
            <i ><img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/favicon-192x192.png?t=2024-10-08T22%3A54%3A28.179Z" height="50" styled={{opacity:"0.85"}}/></i>
              <h4 className="text-warning">Sites de Alta Performance e Conversão</h4>
              <p>Todos os nossos sites são feito de forma estratégica para ter os melhores resultados na rede de pesquisa do google e SEO, com google analytics instalado para acompanhar a performance em tempo real.</p>
            </div>
          </div>
          <div class="col-lg-6 order-2 order-lg-1 px-5">
                <CarouselSite className="pages d-flex mx-auto"/>
          </div>
        </div>

      </div>
    </section>
        </>
    )
}