import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './styles.css';
import 'swiper/css/effect-creative';
import 'swiper/css/navigation';
// import required modules
import { EffectCreative, Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function CarouselFace() {
  return (
    <>
      <Swiper 
      id='face'
      loop={true}
       grabCursor={true}
       effect={'creative'}
       creativeEffect={{
         prev: {
           shadow: true,
           translate: [0, 0, -400],
         },
         next: {
           translate: ['100%', 0, 0],
         },
       }}
       pagination={{
        clickable: true,
      }}
       autoplay={{
        delay: 2000,
        disableOnInteraction: false,
    }}
       modules={[EffectCreative, Autoplay, Pagination, Navigation]}
     
      >
        <SwiperSlide> <img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/campanhas/resultado-1.jpg?t=2024-10-08T23%3A46%3A16.566Z" width="100%" /></SwiperSlide>
        <SwiperSlide> <img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/campanhas/resultado-2.jpg?t=2024-10-08T23%3A46%3A16.566Z" width="100%"/></SwiperSlide>
        <SwiperSlide> <img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/campanhas/resultado-3.jpg?t=2024-10-08T23%3A46%3A16.566Z" width="100%"/></SwiperSlide>
        <SwiperSlide> <img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/campanhas/resultado-4.jpg?t=2024-10-08T23%3A46%3A16.566Z" width="100%"/></SwiperSlide>
        <SwiperSlide> <img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/campanhas/resultado-5.jpg?t=2024-10-08T23%3A46%3A16.566Z" width="100%"/></SwiperSlide>
       
      </Swiper>
    </>
  );
}
