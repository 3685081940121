import '../Competencias/styleCompetencias.css'
export default function Competencias(){
    return(
        <>
        <div id="competencias" className='container mb-5'>
        <div class="section-title p-3">
                        <h2>Vamos projetar o marketing da sua empresa conforme a necessidade dela</h2>
                        <p>Tenha um braço a mais para ajudar seu time ou terceirize todo seu marketing com a Promova Online por uma valor acessível, dessa forma você tem varias skills em sua empresa, sem se preocupar com contratação, gestão desse time, riscos trabalhistas e outras burocracias. </p>
                    </div>
        <img className="competencias" src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/profissionais.png?t=2024-10-09T13%3A52%3A56.334Z"/>
                   {/* <div className='text-center'>
                   <a className="btn btn-lg btn-warning fw-bold fs-2 rounded-pill" src="https://api.whatsapp.com/send/?phone=5561992387326&text=Ol%C3%A1+%2APromova+Online%2A%21+Gostaria+de+mais+informações+sobre+o+serviço+de+vocês+%3F(site)&type=phone_number&app_absent=0">Preciso de um time assim</a>
                   </div> */}
        </div>
        </>
    )
}