import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

import './styles.css';

// import required modules
import { EffectCards } from 'swiper/modules';

export default function CarouselSite() {
    return (
        <>
            <Swiper

                loop={true}

                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                effect={'cards'}
                grabCursor={true}
                modules={[EffectCards, Pagination, Navigation, Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide><img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/sites/bellacestas-1.png?t=2024-10-08T21%3A23%3A15.455Z" width="70%" height="auto" /></SwiperSlide>
                <SwiperSlide><img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/sites/netsim-1.png?t=2024-10-08T21%3A23%3A15.455Z" width="70%" height="auto" /></SwiperSlide>
                <SwiperSlide><img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/sites/netsim-2.png?t=2024-10-08T21%3A23%3A15.455Z" width="70%" height="auto" /></SwiperSlide>
                <SwiperSlide><img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/sites/startec-1.png?t=2024-10-08T21%3A23%3A15.455Z" width="70%" height="auto" /></SwiperSlide>
                <SwiperSlide><img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/sites/jofran-1.png?t=2024-10-08T21%3A23%3A15.455Z" width="70%" height="auto" /></SwiperSlide>
                <SwiperSlide><img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/sites/promova-1.png?t=2024-10-08T21%3A23%3A15.455Z" width="70%" height="auto" /></SwiperSlide>

            </Swiper>
        </>
    );
}