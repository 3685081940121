import './pricing.css'
export default function ProPrincing() {
  return (
    <>
      <section id="pricing" className="pricing" data-aos="fade-up" data-aos-duration="1000">
        <div className="container">

          <div className="section-title">
            <h2 className="text-light">Você pode personalizar o plano que mais se adeque ao seu momento</h2>
            <p>Todos esses serviços são divididos em planos. Você pode ajustar conforme sua disponibilidade de investimento. O importante é não desistir de investir no que vai transformar a realidade do seu negócio.</p>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6" >
              <div className="box-1">
                <div className="box" data-aos="zoom-in" data-aos-duration="300">
                  <h3> Presença</h3>
                  <h4>Digital</h4>
                  {/* <h4><sup>R$</sup>99,90<span> / mês</span></h4> */}
                  <ul>
                    <li className="">Copywriting</li>
                    <li className="">Site/ Landing Pages/ Blog</li>
                    <li className="">Google Meu Negócio</li>
                    <li className="">Google Analytics</li>
                         <li className="d-none d-sm-block">-----------</li>
                         <li className="d-none d-sm-block">-----------</li>
                         <li className="d-none d-sm-block">-----------</li>
                         <li className="d-none d-sm-block">-----------</li>
                         <li className="d-none d-sm-block">-----------</li>
                         <li className="d-none d-sm-block">-----------</li>
                         <li className="d-none d-sm-block">-----------</li>
                         <li className="d-none d-sm-block">-----------</li>
                         <li className="d-none d-sm-block">-----------</li>


                  </ul>
                  <div className="btn-wrap">
                    <a href="https://api.whatsapp.com/send/?phone=5561990003695&text=Ol%C3%A1+%2APromova+Online%2A%21+Gostaria+desse+plano+Presença+Digital+%3F(site)&type=phone_number&app_absent=0" className="btn-buy">Saber Mais</a>
                  </div>
                </div>
              </div>

            </div>

            <div className="col-lg-3 col-md-6 mt-4 mt-md-0">
              <div className="box-1">
                <div className="box recommended" data-aos="zoom-in" data-aos-duration="500">
                  <h3> Visibilidade</h3>
                  <h4>Online</h4>

                  {/* <h4><sup>R$</sup>199,90<span> / mês</span></h4> */}
                  <ul>
                    <li className="">Copywriting</li>
                    <li className="">Google Ads</li>
                    <li className="">Facebook Ads</li>
                    <li className="">Instagram Ads</li>
                    <li className="d-none d-sm-block">-----------</li>
                    <li className="d-none d-sm-block">-----------</li>
                    <li className="d-none d-sm-block">-----------</li>
                    <li className="d-none d-sm-block">-----------</li>
                    <li className="d-none d-sm-block">-----------</li>
                    <li className="d-none d-sm-block">-----------</li>
                    <li className="d-none d-sm-block">-----------</li>
                    <li className="d-none d-sm-block">-----------</li>
                    <li className="d-none d-sm-block">-----------</li>




                  </ul>
                  <div className="btn-wrap">
                    <a href="https://api.whatsapp.com/send/?phone=5561990003695&text=Ol%C3%A1+%2APromova+Online%2A%21+Gostaria+desse+plano+Visibilidade+Online+%3F(site)&type=phone_number&app_absent=0" className="btn-buy">Saber Mais</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
              <div className='box-1'>
                <div className="box" data-aos="zoom-in" data-aos-duration="700">
                  <span className="recommended-badge">Popular</span>

                  <h3> Domínio</h3>
                  <h4>Digital</h4>

                  {/* <h4><sup>R$</sup>299,90<span> / mês</span></h4> */}
                  <ul>
                    <li className="">Copywriting</li>
                    <li className="">Site/ Landing Pages/ Blog</li>
                    <li className="">Google Meu Negócio</li>
                    <li className="">Google Analytics</li>
                    <li className="">Email Marketing</li>
                    <li className="">Google Ads</li>
                    <li className="">Facebook Ads</li>
                    <li className="">Instagram Ads</li>
                    <li className="">Design-Criativos</li>
                    <li className="d-none d-sm-block">-----------</li>
                    <li className="d-none d-sm-block">-----------</li>
                    <li className="d-none d-sm-block">-----------</li>
                    <li className="d-none d-sm-block">-----------</li>

                  </ul>
                  <div className="btn-wrap">
                    <a href="https://api.whatsapp.com/send/?phone=5561990003695&text=Ol%C3%A1+%2APromova+Online%2A%21+Gostaria+desse+plano+Domínio+Digital+%3F(site)&type=phone_number&app_absent=0" className="btn-buy">Saber Mais</a>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
              <div className='box-1'>
                <div className="box recommended" data-aos="zoom-in" data-aos-duration="900">
                  <span className="recommended-badge">Recomendado</span>

                  <h3> Transformação</h3>
                  <h4>Digital</h4>

                  {/* <h4><sup>R$</sup>499,90<span> / mês</span></h4> */}
                  <ul>
                    <li className="">Copywriting</li>
                    <li className="">Site/ Landing Pages/ Blog</li>
                    <li className="">Google Meu Negócio</li>
                    <li className="">Google Analytics</li>
                    <li className="">Email Marketing</li>
                    <li className="">SEO (Blog)</li>
                    <li className="">Google Ads</li>
                    <li className="">Facebook Ads</li>
                    <li className="">Instagram Ads</li>
                    <li className="">Design-Criativos</li>
                    <li className="">Video Maker</li>
                    <li className="">CRM</li>
                    <li className="">Sales Ops</li>



                  </ul>
                  <div className="btn-wrap">
                    <a href="https://api.whatsapp.com/send/?phone=5561990003695&text=Ol%C3%A1+%2APromova+Online%2A%21+Gostaria+desse+plano+Tranformação+Digital+%3F(site)&type=phone_number&app_absent=0" className="btn-buy">Saber Mais</a>
                  </div>
                </div>
              </div>
            </div>


          </div>

        </div>
      </section>

    </>
  )
}