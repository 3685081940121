
export default function ProHero() {
    return(
        <>

            <section id="hero" class="d-flex flex-column justify-content-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8">
          {/* <img src="https://dlzumwzvsvpdqblhfoof.supabase.co/storage/v1/object/public/PromovaOnline/Logos/logo_1.png?t=2024-07-11T15%3A41%3A09.374Z"/> */}
          <h1 className="text-light display-4 fw-bold ">Venda todos os dias com soluções de <span className="text-warning ">Marketing Digital</span></h1>
          <h2>Sua empresa sempre online, sempre visível e sempre vendendo!</h2>
          <a className="btn btn-warning btn-lg fw-bold fs-2 rounded-pill " href="https://api.whatsapp.com/send/?phone=5561992387326&text=Ol%C3%A1+%2APromova+Online%2A%21+Gostaria+de+mais+informações+sobre+o+serviço+de+vocês+%3F(site)&type=phone_number&app_absent=0">Quero Vender Mais</a>
          {/* <a href="https://calendar.app.google/HX5p38VJhPfqzA5D6" class="glightbox play-btn"></a> */}
        </div>
      </div>
    </div>
  </section>
        
        </>
    )
}