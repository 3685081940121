export default function ProAbout() {
    return(
        <>
         <section id="about" class="about" data-aos="fade-up" data-aos-duration="1000">
      <div class="container text-center">

        <div class="section-title">
          <h2 >O nosso negócio é VENDER o seu!</h2>
          <p className="fs-2">Bem-vindo à <b>PromovaOnline</b>, a assessoria de marketing dedicada desde 2019 a transformar ideias em resultados tangíveis.<br/> Com uma abordagem inovadora e estratégias personalizadas, para impulsionar a visibilidade da sua marca, atrair seu público-alvo e alcançar metas de VENDAS excepcionais de forma automatizada e eficiente.</p>
          <a href="https://api.whatsapp.com/send/?phone=5561990003695&text=Ol%C3%A1+%2APromova+Online%2A%21+Gostaria+de+mais+informações+sobre+o+serviço+de+vocês+%3F(site)&type=phone_number&app_absent=0" className="btn-learn-more text-center fs-3">Conheça agora as soluções que temos para seu negócio!</a>
        
        </div>
        </div>
    </section>
        
        </>
    )
}