import "../Chatbot/chatbot.css"
export default function Chatbot() {
    return (
        <>
            <div className="px-3">
                <div className="container my-5" id="chatbot" >
                    <div className="row p-2 pb-0 pe-lg-0 pt-lg-3 align-items-center shadow-lg">
                        <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
                            <img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/leadsmov-256x256.png?t=2024-10-08T23%3A24%3A29.018Z" width="256px"/>
                            <h1 className="display-6 fw-bold lh-1 text-body-emphasis">Sistema de Multi-Atendimento e CRM acessível</h1>
                            <p className="lead text-dark">Conecte suas equipes com clientes e leads através de uma plataforma integrada ao WhatsApp. Garanta rápida resposta dos seus leads, filtrando por ordem de entrada e segmentando por vendedor. Utilize nossa solução de campanhas para enviar mensagens em massa, enviando promoções e novos produtos.</p>
                            {/* <ul className="text-dark">
                                <li> Atendimento automatizado-Chatbot</li>
                                <li>Atendimento com IA  24h x 7 dias </li>
                                <li>Disparo em massa     </li>
                                <li>Gestão de Leads-CRM  </li>
                                <li>Divisão de setores   </li>
                                <li>Multi-Conexões       </li>
                                <li>Dashboard            </li>
                            </ul> */}
                            <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
                                <button type="button" className="btn btn-warning btn-lg px-4 me-md-2 fw-bold" href="#">Saber Mais</button>
                            </div>
                        </div>
                        <div className="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg">
                            <img className="rounded-3" src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/imagem-chatbot.jpg?t=2024-10-08T23%3A07%3A08.553Z" alt="" width="980" />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}