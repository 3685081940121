import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Modals from "../Modals";
import "../Menu/Menu.css"

export default function Menu() {

    const Applist = [
        {
            label: "Clube de descontos",
            description: "App de decontos em estabelecimentos. Consulte seu login!",
            link: "https://qrco.de/bcW6T9",
            config: <Modals
                title="Clube de desconto"
                buttonclass="btn btn-outline-primary"
                buttonName="Configurar"

            />
        },
        {
            label: "Portal de Aplicativos",
            description: "Apps de Streaming. Consulte seu login!",
            link: "https://qrco.de/bcW6T9",
            config: <Modals
                title="Clube de desconto"
                buttonclass="btn btn-outline-primary"
                buttonName="Configurar"
                conteudo={<iframe src="https://comologar.com.br/" name="comologarapps" width="100%" height="100%"></iframe>}

            />
        },
        {
            label: "Central do Cliente",
            description: "App para companhamento e pagamento de fatura via pix e codigo de barras. Consulte seu login!",
            link: "https://ixc.netsimtelecom.com.br/central_assinante_web/login",
            config: <a className="btn btn-outline-primary" href='https://qrco.de/bcWAGR'>Baixar</a>
        },
        {
            label: "Netsim Play- TV",
            description: "App com canais de tv, filmes e séries. Consulte seu login!",
            link: "/netsim-play",
        }

    ]

    return (
        <>
            <Navbar  key="md" expand="md" className="sticky-top" id="menu" style={{padding:"5px"}}>
                <Container style={{padding:"0"}}>
                    <Navbar.Brand className='navlogo' href="/">
                       <img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/favicon-192x192.png" height="35px" width="auto" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto "  >
                            <a className="navlink fs-6 mx-2" href="/">Home</a>
                            <a className="navlink fs-6 mx-2" href="/faq">FAQ</a>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>

    );
}
