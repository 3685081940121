import './styleCompetencias.css'
export default function LoopGrowth(){
    return(
        <>
        <div id="competencias" className='container mb-5'>
        <div class="section-title p-3">
                        <h2>Existe um método científico para que sua empresa nunca pare de vender</h2>
                        <p>Se você não seguí-lo, ela não vai sair do lugar!</p>
                    </div>
        <img className="competencias d-none d-sm-block" src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/loop-growth.png?t=2024-10-09T15%3A06%3A52.027Z"/>
        <img className="competencias d-sm-none" src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/loop-growth-mobile.png?t=2024-10-09T15%3A06%3A52.027Z"/>
                   {/* <div className='text-center'>
                   <a className="btn btn-lg btn-warning fw-bold fs-2 rounded-pill" src="https://api.whatsapp.com/send/?phone=5561992387326&text=Ol%C3%A1+%2APromova+Online%2A%21+Gostaria+de+mais+informações+sobre+o+serviço+de+vocês+%3F(site)&type=phone_number&app_absent=0">Preciso de um time assim</a>
                   </div> */}
        </div>
        </>
    )
}